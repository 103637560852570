<template>
  <FilePond
    ref="pond"
    name="image"
    :label-idle="$t('drop_files_here') + ' ' + labelExtra"
    :allow-multiple="allowMultiple"
    allow-file-encode
    :accepted-file-types="acceptedFileTypes"
    :max-files="maxFiles"
    :allow-reorder="true"
    :instant-upload="false"
    :files="myFiles"
    @updatefiles="onUpdateFiles"
    @init="handleFilePondInit"
  />
</template>

<script>
// Import Vue FilePond
import vueFilePond from "vue-filepond";

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";

//import FormData from "form-data";
// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginFileEncode
);

export default {
  components: {
    FilePond
  },
  props: {
    acceptedFileTypes: {
      type: String,
      default: "image/png"
    },
    allowMultiple: {
      type: Boolean,
      default: true
    },
    images: {
      type: [Array, File],
      default: () => []
    },
    maxFiles: {
      type: Number,
      default: 6
    },
    labelExtra: {
      type: String,
      default: ""
    },
  },
  data: function() {
    return {
      myFiles: []
    };
  },
  methods: {
    handleFilePondInit: function() {
      for (var j = 0; j < this.images.length; j++) {
        let b = this.b64toBlob(this.images[j]["content"], "image/png");
        var file = new File([b], this.images[j]["name"], {
          type: "image/png",
          lastModified: new Date()
        });

        this.$refs.pond.addFile(file);
      }
    },
    b64toBlob(dataURI, type) {
      var byteString = atob(dataURI.split(",")[1]);
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);

      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: type });
    },
    onUpdateFiles() {
      let myFiles = this.$refs.pond.getFiles().map(files => files.file);
      this.$emit("updateFiles", myFiles);
    }
  }
};
</script>
